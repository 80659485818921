exports = module.exports = require("../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.list_AB3Cc_Fy {\r\n  display: -webkit-box;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  overflow: auto;\n}\n.item_1o95kAD2 {\r\n  -ms-flex-negative: 0;\r\n      flex-shrink: 0;\r\n  margin: 0 4px\n}\n.item_1o95kAD2::after {\r\n  position: relative;\r\n  left: 4px;\r\n  content: '/';\n}\n.item_1o95kAD2:last-child::after {\r\n  content: '';\n}\r\n", "", {"version":3,"sources":["D:/blog/client/src/common/BreadCrumb.vue"],"names":[],"mappings":";AACA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,cAAc;EACd,eAAe;CAChB;AACD;EACE,qBAAqB;MACjB,eAAe;EACnB,aAAa;CACd;AACD;EACE,mBAAmB;EACnB,UAAU;EACV,aAAa;CACd;AACD;EACE,YAAY;CACb","file":"BreadCrumb.vue","sourcesContent":["\n.list {\r\n  display: -webkit-box;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  overflow: auto;\n}\n.item {\r\n  -ms-flex-negative: 0;\r\n      flex-shrink: 0;\r\n  margin: 0 4px\n}\n.item::after {\r\n  position: relative;\r\n  left: 4px;\r\n  content: '/';\n}\n.item:last-child::after {\r\n  content: '';\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"list": "list_AB3Cc_Fy",
	"item": "item_1o95kAD2"
};