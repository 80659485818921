exports = module.exports = require("../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.wrap_3ZJ6tKP8 {\r\n  padding: 10px;\r\n  margin: 0 auto;\r\n  background: #fff;\r\n  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);\r\n          box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);\n}\n.list_yS1I5-rL {\r\n  border: 1px solid #aaa;\r\n  border-radius: 4px;\n}\n.item_3Np5clRm {\r\n  padding: 10px;\r\n  border-top: 1px solid #ccc;\r\n  line-height: 20px;\r\n  cursor: pointer\n}\n.item_3Np5clRm:first-child {\r\n  border: none;\n}\r\n", "", {"version":3,"sources":["D:/blog/client/src/common/LinkageSelector.vue"],"names":[],"mappings":";AACA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,uHAAuH;UAC/G,+GAA+G;CACxH;AACD;EACE,uBAAuB;EACvB,mBAAmB;CACpB;AACD;EACE,cAAc;EACd,2BAA2B;EAC3B,kBAAkB;EAClB,eAAe;CAChB;AACD;EACE,aAAa;CACd","file":"LinkageSelector.vue","sourcesContent":["\n.wrap {\r\n  padding: 10px;\r\n  margin: 0 auto;\r\n  background: #fff;\r\n  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);\r\n          box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);\n}\n.list {\r\n  border: 1px solid #aaa;\r\n  border-radius: 4px;\n}\n.item {\r\n  padding: 10px;\r\n  border-top: 1px solid #ccc;\r\n  line-height: 20px;\r\n  cursor: pointer\n}\n.item:first-child {\r\n  border: none;\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrap": "wrap_3ZJ6tKP8",
	"list": "list_yS1I5-rL",
	"item": "item_3Np5clRm"
};