exports = module.exports = require("../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.input_tIIuZOPj {\n  display: block;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  width: 100%;\n  padding: 10px 20px;\n  border: 1px solid #e5e5e5;\n  border-radius: 4px 4px 0 0;\n  font-size: 1.1em;\n  line-height: 18px;\n  letter-spacing: 1px;\n  color: #333\n}\n.input_tIIuZOPj:focus {\n  border-bottom: 1px solid #666;\n  outline: none;\n}\n", "", {"version":3,"sources":["D:/blog/client/src/common/BaseInput.vue"],"names":[],"mappings":";AACA;EACE,eAAe;EACf,+BAA+B;UACvB,uBAAuB;EAC/B,YAAY;EACZ,mBAAmB;EACnB,0BAA0B;EAC1B,2BAA2B;EAC3B,iBAAiB;EACjB,kBAAkB;EAClB,oBAAoB;EACpB,WAAW;CACZ;AACD;EACE,8BAA8B;EAC9B,cAAc;CACf","file":"BaseInput.vue","sourcesContent":["\n.input {\n  display: block;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  width: 100%;\n  padding: 10px 20px;\n  border: 1px solid #e5e5e5;\n  border-radius: 4px 4px 0 0;\n  font-size: 1.1em;\n  line-height: 18px;\n  letter-spacing: 1px;\n  color: #333\n}\n.input:focus {\n  border-bottom: 1px solid #666;\n  outline: none;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"input": "input_tIIuZOPj"
};